
@font-face {
  font-family: 'retro';
  src: url(./fonts/pressstart.ttf);
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  color: white;
  width: 100vw;
  /* padding: 43px; */
}

section {
  padding: 40px;
}

.background_video {
  /* border: 1px dashed orange; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%; 
  height: 110%;
  z-index: -2;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: black;
}

.background_video_overlay {
  background: rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: -1;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px;
  background: linear-gradient(to bottom, white, black);
  color: brown;
  font-family: retro;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* height: 60px; */
  z-index: 3;
}

.kaputzlogo { 
  margin: 0;
  width: 170px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(images/kaputzlogo.png);
}

.appTitle {
  /* position: fixed; */
  /* left: 20px; */
  /* top: 10px; */
  font-size: large;
  /* width: auto; */
  /* display: none; */
  color: orange;
}

.authenticationMenu {
  position: fixed;
  right: 30px;
  top: 30px;
  /* display: none; */
}

.signInButton, .signUpButton {
  font-size: x-large;
  font-weight: bold;
  margin-right: 6px;
}

.signInButton:hover, .signUpButton:hover {
  cursor: pointer;
}

img {
  /* border: 1px solid red; */
  margin: auto;
  /* border-radius: 40px; */
}

.downloadButton {
  margin: auto;
  width: auto;
  min-width: 400px;
  height: 200px;
  background-size: fit;
  background-repeat: no-repeat;
  background-position: center;
}

.gameList {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: auto;
  background: rgba(0,0,0,0.5);
  margin-right: 50px;
  margin-left: 50px;
}

.GameOption{
  width: fit-content;
  margin: auto;
  text-align: center;
}

.GameOption > span {
  font-size: x-large;
}

.GameOption > img {
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.GameOption > button  {
  width: 100%;
  height: 40px;
}

.GameOption > button:hover {
  color: white;
  background: orange;
  cursor: pointer;
}

.orangebutton { 
  border-radius: 20px;
  background: linear-gradient(orange, white, orange);
  border: 0;
}

.orangebutton:hover {
  border-radius: 20px;
  background: linear-gradient(darkorange, white, darkorange);
  border: 0;
}

@media only screen and (max-width: 760px){
  .signInButton, .signUpButton {
    font-size: small;
  }

  .gameList {
    grid-template-columns: 1fr;
  }

  .authenticationMenu {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
  }
}
